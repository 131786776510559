/*******************************************************************************
 * NFL Confidence Pool FE - the frontend implementation of an NFL confidence pool.
 * Copyright (C) 2015-present Brian Duffey
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see {http://www.gnu.org/licenses/}.
 * Home: https://asitewithnoname.com/
 */
@import "../../styles/variables";

.btn-google {
  color: $google-text;
  background-color: $google-background;
  border-color: $google-background;

  &:active {
    color: $google-text;
    background-color: darken($google-background, 20%);
    border-color: darken($google-background, 10%);
  }

  &:hover {
    color: $google-text;
    background-color: darken($google-background, 15%);
    border-color: darken($google-background, 10%);
  }
}

.btn-twitter {
  color: $twitter-text;
  background-color: $twitter-background;
  border-color: $twitter-background;

  &:active {
    color: $twitter-text;
    background-color: darken($twitter-background, 20%);
    border-color: darken($twitter-background, 10%);
  }

  &:hover {
    color: $twitter-text;
    background-color: darken($twitter-background, 15%);
    border-color: darken($twitter-background, 10%);
  }
}
