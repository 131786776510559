/*******************************************************************************
 * NFL Confidence Pool FE - the frontend implementation of an NFL confidence pool.
 * Copyright (C) 2015-present Brian Duffey
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see {http://www.gnu.org/licenses/}.
 * Home: https://asitewithnoname.com/
 */
@import "./variables";

.football {
  position: absolute;
  top: -96px;
  left: 50%;
  width: 140px;
  height: 140px;
  margin-left: -70px;
}

.separator {
  display: flex;
  align-items: center;
  color: $gray-4;
  text-align: center;

  &::before,
  &::after {
    flex: 1;
    content: "";
    border-bottom: 1px solid $gray-4;
  }

  &:not(:empty)::before {
    margin-right: 2em;
  }

  &:not(:empty)::after {
    margin-left: 2em;
  }
}

.error {
  font-size: 23px;
  // stylelint-disable-next-line font-weight-notation
  font-weight: bold;
  line-height: 27px;
  color: $red-3;
}
